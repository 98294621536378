<template>
  <div class="dswindow">
    <div class="electrical">
      <div class="title">
        {{ deviceData.deviceName }}
      </div>
      <ul class="col">
        <li>当前状态：{{ currentStatus[deviceData.switching] }} </li>
        <!-- <li>当前状态时长： <span>{{deviceData.currentStateRuntime}}</span> 小时</li> -->
      </ul>
      <!-- <div class="item">
        总运行时长： <span>{{deviceData.visualizationTotalTime}}</span> 小时
      </div>
      <div class="item">
        预测更换时间：{{deviceData.predictedReplacementTime}}
      </div> -->
    </div>
  </div>
</template>

<script>
import { getLightList } from '@/api'
export default {
  data() {
    return {
      filterData: {
        deviceNumber: "",
        pageIndex: 1,
        pageSize: 1000
      },
      currentStatus: { true: '开', false: '关' },
      deviceData: {}
    }
  },
  props: {

  },
  mounted() {
    this.$emit('loading')
    this.filterData.deviceNumber = this.$route.query.deviceNumber
    if (this.$store.state.token) {
      this.init()
    }
  },
  methods: {
    init() {
      getLightList(this.filterData).then(res => {
        this.deviceData = res.data.data[0]
      })
    }
  },
  watch: {
    '$store.state.token': {
      deep: true,
      handler(val) {
        console.log('token变化了', val);
        this.init()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.dswindow {
  width: 100%;
  height: 100%;

  .electrical {
    width: 378px;
    height: 84px;
    background: url('~@/assets/images/gd/deng_bg2@2x.png') no-repeat;
    background-size: 100% 100%;
    box-sizing: border-box;
    padding: 16px;
    color: #3acbe0;
    font-size: 15px;
    font-family: MicrosoftYaHei;

    .title {
      font-size: 18px;
      color: #fff;
      font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
    }

    .col {
      display: flex;
      padding-top: 10px;

      li {
        width: 50%;
      }
    }

    span {
      color: #fdcc30;
    }

    .item {
      padding-top: 10px;
      font-size: 15px;
    }
  }
}</style>